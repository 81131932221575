import React from 'react'
import Layout from '../components/Layout'
import contactImage from '../img/contactushero.png'
import breadcrumb from '../img/breadcrumb.png'
import ContactForm from "../components/ContactForm";
import {graphql} from "gatsby";


export const ContactTemplate = ({address, phone, email, internshipintro, internshiptext}) => {

  return (<div className="content contact-us">
    <div className={'container'}>
      <div className={'intro-section'}>
        <h1 className={'title-mobile'}>Contact Us</h1>
        <div className={'image'}>
          <img src={contactImage} alt={'contact us'}/>
        </div>
        <div className={'content'}>
          <h1 className={'title-desktop'}>Contact Us</h1>
          <div className={'about-us-intro'}> Looking for more information on our services? Fill out the form
            below. We're
            ready
            to discuss your strategic aims and help you get results
          </div>
        </div>
      </div>
    </div>
    <div className={'desktop-background'}>
      <div className={'form-and-visit-us container'}>
        <div className={'form-background'}>
          <div className={'container'}>
            <h3 className={'sub-title mobile'}>Send Us A Message</h3>
          </div>
          <div className={'contact-form container'}>
            <h3 className={'sub-title desktop'}>Send Us A Message</h3>
            <ContactForm
              phone={phone}
              email={email}
            />
          </div>
        </div>
        <div className={'visit-us'}>
          <div className={'map-and-address container'}>
            <h3 className={'sub-title'}>Visit Us</h3>
            <div className={'address'}>Littler associates, K202, "The Attrium", 22, Kalakshetra Road,
              Thiruvanmiyur,
              Chennai 600041
            </div>
            <iframe
              title={'littler headquarters'}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.8047130444975!2d80.2556093153418!3d12.98433899084749!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d5c2f0f2c6d%3A0xebd68571f71591a3!2sLittler%20associates!5e0!3m2!1sen!2sin!4v1600361712356!5m2!1sen!2sin"
              width="100%" height="450" className={'maps'} frameBorder="0" style={{border: 0}} allowFullScreen=""
              aria-hidden="false"
              />
          </div>
        </div>
      </div>
    </div>
    <div className={'internship-background'}>
      <div className={'internship container'}>
        <div className={'internship-header'}>
          <img src={breadcrumb} alt={'bulb'}/>
          <h3>INTERNSHIP <span>Program</span></h3>
        </div>
        <div>
          <div className={'internship-intro'}>
            {internshipintro}
          </div>
          <div className={'internship-moreinfo'}>
            {internshiptext} <a
            href={`mailto:${email}`}>{email}</a>
          </div>
        </div>
      </div>
    </div>
  </div>);
};

const Contact = ({data}) => {
  const {frontmatter} = data.markdown;
  return <Layout>
    <ContactTemplate
      email={frontmatter.email}
      phone={frontmatter.phone}
      address={frontmatter.address}
      internshipintro={frontmatter.internshipintro}
      internshiptext={frontmatter.internshiptext}
    />
  </Layout>;
};
export default Contact;

export const pageQuery = graphql`
query ContactUsTemplate {
    markdown: markdownRemark(
      frontmatter: { templateKey: { eq: "contact" } }
    ) {
       frontmatter {
        phone
        email
        address,
        internshipintro
        internshiptext
       }
    }
  }
`
