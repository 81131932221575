import React from 'react';
import {navigate} from "gatsby-link";
import ReCAPTCHA from "react-google-recaptcha";


function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {isValidated: false}
    this.onSuccessRecaptcha = this.onSuccessRecaptcha.bind(this);
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  onSuccessRecaptcha = (value)=> {
    this.setState({'g-recaptcha-response': value});
  }

  render() {
    return <form
      name="contact"
      method="post"
      action="/contact/thanks/"
      data-netlify="true"
      data-netlify-recaptcha="true"
      onSubmit={this.handleSubmit}
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact"/>

      <div className="field">
        <label className="label" htmlFor={'name'}>
          Contact Number
        </label>
        <div className="control">
          <input
            className="input"
            type={'text'}
            name={'name'}
            onChange={this.handleChange}
            id={'name'}
            required={true}
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor={'email'}>
          Email
        </label>
        <div className="control">
          <input
            className="input"
            type={'email'}
            name={'email'}
            onChange={this.handleChange}
            id={'email'}
            required={true}
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor={'message'}>
          Message
        </label>
        <div className="control">
                    <textarea
                      className="textarea"
                      rows="3"
                      name={'message'}
                      onChange={this.handleChange}
                      id={'message'}
                      required={true}
                    />
        </div>
      </div>
      <ReCAPTCHA
        onChange={this.onSuccessRecaptcha}
        sitekey="6LfBjM0ZAAAAALxcR-10jBXdz4nueo0rAlzL46M9"/>
      <div className="field">
        <button className="btn" type="submit">
          Send
        </button>
      </div>
      <div className={'email-and-phone'}>
        <div className={'phone-contact'}>
          <div>Give us a call</div>
          <div><a href={`tel:+91${this.props.phone}`}>+91 {this.props.phone}</a></div>
        </div>
        <div className={'email-contact'}>
          <div>Email Us</div>
          <div><a href={`mailto:${this.props.email}`}>{this.props.email}</a></div>
        </div>

      </div>
    </form>;
  }
}
